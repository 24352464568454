<template>
  <div>
    <webhook-modal
      :value="modal.data.form"
      :shipping-accounts="shippingAccounts"
      :is-shown="modal.data.isOpen"
      :callback-validator="callbackValidator"
      @send="modal.handle"
      @close="modal.close">
      <template #title>
        <template v-if="modal.data.isCreateForm">
          New
        </template> Webhook
      </template>
      <template #send-button-label>
        {{ modal.data.isCreateForm ? "Create" : "Update" }}
      </template>
    </webhook-modal>

    <slot name="message" />

    <z-table
      :loading="loading"
      :fields="fields"
      :data="webhooks">
      <template #title>
        Webhooks
      </template>
      <template #filter-bar>
        <z-button
          type="success"
          @click="modal.showCreateForm">
          Create
        </z-button>
      </template>
      <template #cell(callback)="{ row }">
        <div class="break-all">
          {{ row.callback }}
        </div>
      </template>
      <template #cell(events)="{ row }">
        <template
          v-for="item in row.events"
          :key="item">
          {{ item }}<br>
        </template>
        <!-- this space is needed to not show empty array -->
        &nbsp;
      </template>
      <template #cell(actions)="{ row }">
        <div class="min-w-max text-center">
          <z-button
            class="mr-4"
            secondary
            @click="modal.showEditForm(row)">
            Manage
          </z-button>
          <z-button
            type="danger"
            @click="remove(row.id)">
            Delete
          </z-button>
        </div>
      </template>
    </z-table>
  </div>
</template>

<script>
export default {
  name: 'WebhooksTemplate',
}
</script>

<script setup>
import { defineProps, defineEmits } from 'vue';

import ZButton from '../../atoms/Button';
import ZTable from '../../organisms/Table';
import WebhookModal from '../../organisms/WebhookModal';
import useModal from '../../../mixins/useModal';

defineProps({
  webhooks: {
    type: Array,
    required: true,
  },
  shippingAccounts: {
    type: Array,
    required: true,
  },
  callbackValidator: {
    type: Function,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['create', 'update', 'delete']);
const modal = useModal(emit);

const fields = [
  "name",
  "description",
  "events",
  {key: "callback", label: "URL"},
  {key: "actions", label: ""},
];

const remove = row => emit('delete', row.id);
</script>
